.file-display {
	transition: .1s;
	display: inline-flex;
	gap: 15px;
	border: 1px solid var(--lightGray);
	min-width: 200px;
	margin: 15px;
	padding: 10px;
}

.file-problem {
	border-width: 1px;
	border-color: #FB2B2B !important;
}

.file-problem.no-file {
	color: #FB2B2B !important;
}

.file-display:hover {
	border-color: #c7c7c7;
}

.file-display.no-file {
	color: #888888;
	cursor: unset;
	text-align: center;
	padding-right: 15px;
	padding-left: 15px;
}

.file-display > div {
	min-width: 100px;
}

.file-display .icon {
	min-width: 64px;
	width: 64px;
    cursor: pointer;
}

.file-display .file-infos {
	text-align: center;
	display: inline-flex;
	flex-direction: column;
	padding-right: 15px;
}