.admin-page {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas: "sidebar header"
        "sidebar body";
    min-height: 100vh;
    gap: 30px;
    padding-right: 30px;
}

.admin-page .admin-sidebar {
    grid-area: sidebar;
    min-width: 180px;
    background-color: var(--lightGray);
}

.admin-page .sidebar-title {
    display: block;
    text-decoration: none;
    font-weight: 800;
    color: var(--colTheme);
    font-size: 40px;
    margin: 30px 0;
    padding-left: 30px;
}

.admin-page .sidebar-nav {
    position: sticky;
    top: 0;
}

.admin-page .sidebar-nav-item,
.admin-page .sidebar-dropdown-menu,
.admin-page .dropdown-menu-title {
    transition: .2s;
    font-weight: 600;
    padding: 15px 30px;
    padding-left: 30px;
    position: relative;
    display: block;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

}

.admin-page .sidebar-dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.admin-page .dropdown-menu-title {
    position: relative;
    margin: 0 0 0 -30px;
    padding-left: 30px;
}

.admin-page .sidebar-nav-item.is-selected,
.admin-page .sidebar-nav-item:hover,
.admin-page .sidebar-dropdown-menu.is-opened .dropdown-menu-title,
.admin-page .sidebar-dropdown-menu .dropdown-menu-title:hover {
    color: var(--colTheme);
}

.admin-page .sidebar-nav-item:before,
.admin-page .sidebar-dropdown-menu .dropdown-menu-title:before {
    transition: .2s;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0px;
}

.admin-page .sidebar-nav-item.is-selected:before,
.admin-page .sidebar-nav-item:hover:before,
.admin-page .sidebar-dropdown-menu.is-opened .dropdown-menu-title:before,
.admin-page .sidebar-dropdown-menu .dropdown-menu-title:hover:before {
    width: 3px;
    background-color: var(--colTheme);
}

.admin-page .sidebar-dropdown-menu .dropdown-menu-items {
    transition: .3s;
    max-height: 0;
    overflow: hidden;
}

.admin-page .sidebar-dropdown-menu.is-opened .dropdown-menu-items {
    max-height: 1000px;
    overflow: hidden;
}

.admin-page .sidebar-dropdown-menu .sidebar-nav-item {
    color: #BFBFBF;
}

.admin-page .sidebar-dropdown-menu .sidebar-nav-item:hover {
    color: var(--colTheme);
}

.admin-page .sidebar-dropdown-menu .dropdown-menu-items {
    padding-left: 30px;
}

.admin-page .admin-header {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
}

.admin-header .title {
    font-size: 35px;
    font-weight: 600;
}

.admin-header .paths {
    display: flex;
    font-size: 25px;
    font-weight: 600;
    color: var(--lightGrayText);
}

.admin-header .email {
    display: flex;
    font-size: 18px;
    font-weight: 600;
    color: var(--lightGrayText);
}

.admin-header .paths a {
    text-decoration: none;
    color: inherit;
}

.body-header span {
    display: flex;
    justify-content: space-between;
}

.body-header .buttons {
    width: fit-content;
    display: flex;
    justify-content:flex-end;
    align-items: center;
}

.admin-section {
    padding-bottom: 15px;
}

.admin-section > ul {
    margin-top: 5px;
    margin-bottom: 5px;
}

.paymentMode {
    border: 2px solid var(--lightGray);
    border-radius: 5px;
    margin: 10px;
    padding: 10px 15px;
}

.paymentModeDescr {
    padding-left: 10px;
    padding-top: 10px;
    text-align: justify;
    font-style: italic;
}

.paymentModeDescr > ul {
    margin-top: 6px;
    margin-bottom: 6px;
}

/* ADMIN USER LIST */
.user-action {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    grid-gap: 10px;
    gap: 30px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 5;
}

.user-action > div {
    flex-basis: auto;
    flex-shrink: 0;
    position: relative;
}

.account-links {
    display: flex;
    border: 2px solid var(--lightGray);
    border-radius: 5px;
    margin: 15px 100px;
    padding: 0 40px;
    align-items: center;
}

.account-links>div:nth-child(1),
.account-links>div:nth-child(2) {
    padding: 15px 0;
}

.account-links>div:nth-child(1) {
    color: var(--lightGrayText);
    margin-right: 40px;
    font-size: 15px;
}

.account-links>div:nth-child(2) {
    flex: 1;
}

.admin-body {
    padding-bottom: 45px;
}

.member .member-basic {
    border: 2px solid var(--lightGray);
    border-radius: 5px;
    margin: 15px 20px;
}

.member .member-basic .section-header {
    padding: 0px 40px;
}

.member .member-basic .section-header .section-title {
    padding: 0px;
}

.member .member-basic .section-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
    padding: 30px;
}

.member .member-basic .info-section {
    text-align: justify;
    padding: 20px 30px 20px 30px
}

.member .member-basic .change {
    text-align: justify;
    padding: 10px 20px 0px 15px;
}

.member .member-basic .change > ul {
    margin-top: 5px;
    margin-bottom: 15px;
}

.member .member-basic .section-content > div {
    flex: none;
    /* flex-basis: calc(25% - 20px); */
    display: flex;
    flex-direction: column;
}

.member .member-basic .section-content .section-grid {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
}

.member .member-basic .payment-content {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    padding: 10px 60px;
}

.member .member-basic .payment-content>div {
    display: inline-block;
    flex: 1;
}

.member .member-basic .section-title {
    font-size: 22px;
    margin: 15px 0;
    padding: 0px 40px;
}

.member .member-files .section-content {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-around;
    gap: 10px;
    padding: 10px;
}

.member .member-files .section-content>div {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.member-header {
    box-sizing: border-box;
    min-height: 90px;
    border-bottom: 1px solid var(--lightGray);
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 5px;
    font-size: 16px;
}

.member-header > div {
    display: flex;
    gap: 20px;
    align-items: center;
}

.member-header .member-name {
    font-weight: 600;
    font-size: 20px;
}

.member-pic {
    height: 100px;
    width: 80px;
    border-radius: 25%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.member-pic img {
    height: 100%;
}

/* Input-specific styles */
.auto-input {
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    padding-right: 5px;
}

.auto-input .btn {
    margin-left: 10px;
}

.auto-input .small-btn {
    font-size: 22px;
    padding: 5px;
    max-height: 25px;
    max-width: 24px;
    background-color: var(--lightGray);
    color: var(--lightGrayText);
    border: 2px solid;
}

.auto-input .edit-mode {
    background-color: var(--colLight);
    color: var(--colTheme);
}

.input-integer {
    max-width: 90px;
    min-width: 90px;
}

/* Tooltips */

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
    visibility: hidden;
    min-width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    font-size: 16px;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.4s 0.2s ease-in-out;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.supportForm-text {
    min-width: 800px;
}

/* For collapsible code part (debug) */
.collapsible-header {
    background-color: #f1f1f1;
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    max-width: 1000px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.collapsible-content {
    padding: 10px;
    overflow-x: auto;
    max-width: 1000px;
}

.arrow {
    margin-left: auto;
}