.overlay {
	position: fixed;
	top: 0; bottom: 0;
	left: 0; right: 0;
	background-color: rgba(0, 0, 0, .5);
	z-index: 6;
}

.modal {
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
    color: black;
	min-width: 200px;
	padding: 30px;
	border-radius: 10px;
	z-index: 7;
    opacity: 1 !important;
}

.text-modal > *{
	font-family: 'Open Sans', sans-serif !important;
	box-sizing: border-box;
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	padding: 5px 5px 5px 5px;
}

.modal.fixed {
	position: fixed;
}

.registration-modal {
	width: 75%;
}