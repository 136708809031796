header.header {
	display: flex;
	padding: 0 100px;
	align-items: center;
	height: 80px;
	position: absolute;
	justify-content: space-between;
	top: 0;
	left: 0; right: 0;
}

header.header a {
	text-decoration: none;
}

header.header .logo {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: 3px;
	color: #000000;
	z-index: 5;
	padding: 0px 10px;
}

header.header nav {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}

header.header nav > ul {
	display: flex;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

header.header nav > ul a {
	line-height: 80px;
	padding: 0 15px;
	height: 80px;
	display: block;
	color: #000000;
	font-weight: 600;
}

header.header .contact-btn {
	z-index: 2;
	font-weight: 600;
	color: #000000;
	margin-left: 50px;
}

@media screen and (max-width: 900px) {
	header.header {
		font-size: 15px;
		padding: 0 10px;
	}

	header.header nav {
		display: none;
	}
}