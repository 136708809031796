.form-group {
	display: inline-block;
	position: relative;
	height: 45px;
}

.form-group label {
	white-space: nowrap;
	cursor: text;
	transition: all .1s cubic-bezier(0,0,.1,1);
	box-sizing: border-box;
	min-width: 100%;
	padding: 0 5px;
	color: var(--lightGrayText);
	position: absolute;
	top: 20px;
}

.form-group.has-content label, .form-group.is-focused label {
	top: 0;
	left: 0;
	font-size: 16px;
}

.form-group > input, .form-group > textarea, .form-group .select-input {
	box-sizing: border-box;
	width: 100%;
	margin-top: 17px;
	outline: 0;
	border: 0;
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	padding: 5px 5px 5px 5px;
	height: 30px;
}

.form-group.has-error input {
	color: #FB2B2B;
}

.form-group .input-icon {
	position: absolute;
	right: 5px;
	top: 22px;
}

.form-group.is-valid .input-icon {
	color: #00FF00;
}

.form-group.has-error .input-icon {
	color: #FB2B2B;
}

.form-group .baseline {
	transition: all .1s cubic-bezier(0,0,.1,1);
	position: absolute;
	margin-top: 5px;
	top: 100%;
	left: 0; right: 0;
	background-color: #000000;
	height: 1px;
}

.form-group .baseline-no-margin {
	margin-top: 0px;
}

.form-group.has-error .baseline {
	background-color: #FB2B2B;
	height: 2px;
}

.placeholder-values-item {
	visibility: hidden;
	padding-left: 5px;
	padding-right: 5px;
	margin-right: 30px;
}

.placeholder-values * {
	height: 0;
}

.form-group .errors {
	position: absolute;
	top: 100%;
	padding-top: 10px;
	font-size: 15px;
	color: #FB2B2B;
}

.list-form-group .errors {
	position: absolute;
	top: 100%;
	padding-top: 0px;
	font-size: 15px;
	color: #FB2B2B;
}

.form-group .errors .error-item {
	white-space: nowrap;
}

.form-group.is-disabled.has-content input {
	color: #545454 !important;
}

/* SELECT */

.form-group .select-input .selected-value {
	cursor: pointer;
	padding: 0 5px;
	margin-right: 25px;
}

.form-group .select-options {
	height: 0;
	position: relative;
	z-index: 3;
	margin-top: 9px;
	margin-bottom: 100%;
	clip-path: polygon(-200% 0, -200% 200vh, 200% 200vh, 200% 0);
}

.form-group .select-options .to-slide {
	position: absolute;
	top: 100%;
	left: 0;
	min-width: 100%;
	box-sizing: border-box;
	transition: transform .2s;
	transform: translateY(-105%);
	background-color: #ffffff;
	padding: 10px 10px;
	border-radius: 0 0 5px 5px;
	z-index: 3;
	margin-bottom: 50px;
}

.form-group.is-focused .select-options .to-slide {
	transform: translateY(0);
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.form-group .option-item {
	padding: 5px;
	font-size: 15px;
	border-radius: 5px;
	cursor: pointer;
}

.form-group .option-item:hover {
	color: var(--colTheme);
	background-color: rgba(3, 133, 229, .2);
}

.form-group.group-select .input-icon {
	color: var(--lightGrayText);
	cursor: pointer;
}

/* FILE */
.form-group.group-file {
	transition: border-color .15s;
	border-radius: 5px;
	border: 1px solid #F2F2F2;
	cursor: pointer;
	display: grid;
	grid-template-columns: 100px 1fr 150px;
	height: unset;
	min-height: 70px;
	align-items: center;
}

.form-group.group-file .progress {
	content: "";
	position: absolute;
	left: 0;
	top: 0; bottom: 0;
	background-color: rgba(76, 175, 80, .2);
	z-index: -1;
	border-radius: 5px;
}

.form-group.group-file:hover {
	border-color: var(--colTheme);
}

.form-group.group-file.is-valid .file-icon {
	color: #4CAF50;
}

.form-group.group-file .file-icon, .form-group.group-file .subtitle {
	color: var(--lightGrayText);
}

.form-group.group-file .file-icon {
	justify-self: center;
}

.form-group.group-file .add-file {
	transition: .15s;
	display: flex;
	align-items: center;
}

.form-group.group-file .add-file .upload-icon {
	margin-right: 20px;
}

.form-group.group-file:hover .add-file {
	color: var(--colTheme);
}

.form-group.group-file .dragging-info {
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
	background-color: rgba(76, 175, 80, .8);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-file.has-error {
	border-color: #FB2B2B;
}

@media screen and (max-width: 600px) {

	.form-group.group-file {
		grid-template-columns: 1fr auto;
		padding-left: 10px;
	}

	.group-file .file-icon {
		display: none;
	}

	.group-file .upload-label {
		display: none;
	}
}

/* PHONE */
.group-phone input {
	color: transparent !important;
}

.group-phone.has-content input,
.group-phone.is-focused input {
	color: #000000 !important;
}

.group-phone.has-error.has-content input {
	color: #FB2B2B !important;
}

/* CHECKBOX */
.form-group.group-checkbox {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.form-group.group-checkbox.inline {
	height: unset;
}

.form-group.group-checkbox * {
	cursor: pointer;
}

.form-group.group-checkbox.is-disabled, .form-group.group-checkbox.is-disabled * {
	cursor: unset;
}

.form-group.group-checkbox .input-check {
	transition: all .1s;
	color: #4caf50;
	border: 2px solid var(--lightGrayText);
	border-radius: 5px;
	width: 15px;
	height: 15px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 15px;
}

.form-group.group-checkbox.is-checked .input-check {
	color: #4caf50;
	border-color: #4caf50;
}

.form-group.group-checkbox label {
	position: initial;
}

/* RADIO */
.form-group.group-radio {
	height: unset;
}

.form-group.group-radio .errors {
	position: static;
	padding-top: 0px;
	padding-bottom: 15px;
	font-size: 15px;
	color: #FB2B2B;
}

.form-group.group-radio.inline {
	gap: 20px;
	height: unset;
	display: flex;
}


/* DATE */
.form-group.group-date {
	min-width: 150px;
	position: relative;
}

.form-group.group-date label {
	top: 0px;
	font-size: 16px;
}

.form-group.group-date input {
	border: 0;
	outline: none;
}

.form-group .react-date-picker__wrapper {
	border: 0;
}

.form-group .react-date-picker  {
	margin-top: 17px;
	padding: 5px;
}

.form-group .react-date-picker {
	display: none;
}

.form-group.is-focused .react-date-picker, .form-group.has-content .react-date-picker {
	display: inline-flex;
}

/* TEXTAREA */
.form-group.group-textarea {
	display: block;
	height: unset;
}

.form-group.group-textarea > textarea {
	resize: none;
    overflow: hidden;
}

/* MULTIPLE SELECT */
.multiple-select {
	z-index: 5;
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #fff;
	border-radius: 5px;
	padding: 10px;
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.multiple-select.is-visible {
	display: block;
}

.multiple-select .form-group.group-checkbox {
	height: 30px;
}

/* -------------------------------------------------------
--------------- LIST FORM GROUP -----------------
------------------------------------------------------- */

.list-form-group {
	display: flex;
	flex-direction: column;
	position: relative;
}

.single-field {
	position: relative;
}

.list-form-group .single-field label {
	white-space: nowrap;
	cursor: text;
	transition: all .1s cubic-bezier(0,0,.1,1);
	box-sizing: border-box;
	min-width: 100%;
	padding: 0 5px;
	color: var(--lightGrayText);
	position: absolute;
	top: 20px;
}

.list-form-group .has-content label, .list-form-group .is-focused label {
	top: 0;
	font-size: 16px;
}

.list-form-group .single-field > input, .list-form-group .single-field  > textarea, .list-form-group .single-field .select-input {
	box-sizing: border-box;
	width: 100%;
	margin-top: 17px;
	outline: 0;
	border: 0;
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
	padding: 5px 5px 5px 5px;
	height: 30px;
}

.list-form-group .single-field .has-error input {
	color: #FB2B2B;
}

.list-form-group .single-field .input-icon {
	position: absolute;
	right: 5px;
	top: 22px;
}

.list-form-group .single-field .is-valid .input-icon {
	color: #00FF00;
}

.list-form-group .single-field .has-error .input-icon {
	color: #FB2B2B;
}

.single-field .baseline {
	transition: all .1s cubic-bezier(0,0,.1,1);
	position: absolute;
	margin-top: 5px;
	top: 100%;
	left: 0; 
	right: 0;
	background-color: #000000;
	height: 1px;
}

.list-form-group .single-field .has-error .baseline {
	background-color: #FB2B2B;
	height: 2px;
}

.list-form-group .single-field .errors {
	position: absolute;
	top: 100%;
	padding-top: 10px;
	font-size: 15px;
	color: #FB2B2B;
}

.list-form-group .errors .error-item {
	white-space: nowrap;
}

.list-form-group.is-disabled.has-content input {
	color: #545454 !important;
}

.medicActInput {
    min-width: 400px;
}