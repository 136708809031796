:root {
    --blueCSSB:rgba(3, 133, 229, 1);
	--lightBlueCSSB:rgba(3, 133, 229, .1);
	--redCSSB:rgba(167, 23, 23, 1);
	--lightRedCSSB:rgba(167, 23, 23, .1);
	--colTheme: var(--blueCSSB);
	--colLight: var(--lightBlueCSSB);
	--lightGray: #e9e9e9;   /* Used for borders, section header, ... originally to #F1F1F4 */
	--lightGrayText: #979797;	/* Used for gray text, bit darker than lightGray */
}

body {
	margin: 0;
    padding: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	font-size: 18px;
}

a {
    color: var(--blueCSSB);
}


@media screen and (max-width: 900px) {
	.no-mobile {
		display: none !important;
	}
}

@media screen and (min-width: 900px) {
	.no-computer {
		display: none !important;
	}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid {
	display: grid;
	gap: 30px;
	grid-template-columns: 300px 300px 300px;
}

p {
	margin: 0;
}

.btn {
	transition: .4s;
	cursor: pointer;
	border-radius: 5px;
	background-color: var(--colTheme);
	color: #ffffff;
	padding: 5px 15px;
	margin: 5px 0px 5px 10px;
	display: block;
	text-decoration: none;
	border: 2px solid var(--colTheme);
	font-weight: 500;
	font-size: 18px;
	position: relative;
}

.valid-btn {
	background-color: #378532;
	border: 2px solid #378532;
}

.report-btn {
	background-color: #d28f3c;
	border: 2px solid #d28f3c;
}

.danger-btn {
	background-color: #ff2b2b;
	border: 2px solid #ff2b2b;
}

.danger-btn {
	background-color: #ff2b2b;
	border: 2px solid #ff2b2b;
}

.help-btn {
    background-color: var(--lightGrayText);
    border: 2px solid var(--lightGrayText);
}

.small-btn {
	font-size: 22px;
	padding: 5px;
	max-height: 25px;
	min-width: 24px;
}

.outline-btn {
	background-color: transparent;
	color: var(--colTheme);
}

.btn.is-disabled {
	pointer-events: none;
	background-color: var(--lightGrayText);
	border-color: var(--lightGrayText);
}

.full-page-card {
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.6);
	text-align: center;
	min-width: 40vw;
	border-radius: 5px;
	padding: 0 20px;
	display: flex;
	flex-direction: column;
	max-width: 800px;
    background-color: rgba(255, 255, 255, 0.9);
}

.event-card {
	box-shadow: 2px 2px 10px rgba(83, 83, 83, 0.4);
	border-radius: 5px;
	border: 2px solid var(--blueCSSB);
	padding: .6em 1em;
	margin: 1em auto;
	background-color: var(--lightBlueCSSB);
	max-width: 1200px;
}

.calEvent-card {
	box-shadow: 2px 2px 10px rgba(83, 83, 83, 0.4);
	border-radius: 5px;
	border: 2px solid var(--lightGray);
	padding: .6em 1em;
	margin: 1em auto;
	max-width: 1200px;
}

.blue-title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	padding: 20px 0;
	color: var(--colTheme);
	font-size: 25px;
	text-transform: uppercase;
}

.left .blue-title,
.blue-title.left {
	justify-content: flex-start;
}

.full-page-card .blue-title {
	border-bottom: 2px solid #e0e0e0;
}

.full-page-card .card-content {
	padding: 20px 0;
	flex: 1;
}

main {
	padding: 80px 0;
	box-sizing: border-box;
	min-height: 100vh;
}

.separator {
	height: 2px;
	background-color: #a3a3a3;
	margin: -0px 20px;
}

.vertical-line {
	/* Make a vertical line that is 2 px large */

	width : 2px;
	background-color: #a3a3a3;
	height: 40px;
}

.center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.margin-8 {
	margin: 8px 0;
}

.margin-9 {
	margin: 9px 0;
}

.margin-10 {
	margin: 10px 0;
}

.margin-auto {
	margin: auto;
}

.margin-sides-10 {
	margin: 0 10px;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.btn-link {
	cursor: pointer;
	display: block;
	font-weight: 600;
	text-decoration: none;
}

.blue-link {
	color: var(--colTheme);
	cursor: pointer;
}

.forbidden {
	cursor: not-allowed !important;
}

.form-btn-group {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;
	margin-bottom: 15px;
}

.register-btn-group {
	display: flex;
	justify-content: right;
    padding-top: 5px;
}

.container {
	max-width: 1200px;
	margin: auto;
}

.sub-component {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: 1fr 2fr;
	grid-template-areas: "nav form"
						 "left form";
	gap: 30px 50px;
	padding-top: 40px;
}

.sub-component .nav {
	grid-area: nav;
}

.sub-component .step-left {
	grid-area: left;
}

.sub-component .step-right {
	grid-area: form;
}

@media screen and (max-width: 750px) {
	.sub-component {
		padding: 0 10px;
		grid-template-rows: auto auto 1fr;
		grid-template-columns: 1fr;
		grid-template-areas: "nav"
							 "left"
							 "form";
		gap: 10px;
	}
}

.color-grey {
	color: var(--lightGrayText);
}

.infos-box {
	border-radius: 5px;
	background-color: var(--colLight);
	color: var(--colTheme);
	padding: 20px;
	font-weight: 600;
}

.infos-box-bis {
	border-radius: 5px;
	background-color: var(--colLight);
	color: var(--colTheme);
	padding-right: 30px;
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 600;
}

.infos-box.orange {
	color: #FBA433;
	background-color: rgba(251, 164, 51, .1);
}

.infos-box.purple {
	color: #744CAF;
	background-color: rgba(116, 76, 175, .1);
}

.infos-box.red {
	color: #FB2B2B;
	background-color: rgba(255, 0, 0, .1);
}

.infos-box.green {
	color: #4CAF50;
	background-color: rgba(0, 255, 0, .1);
}

.infos-box.blue {
	color: var(--colTheme);
	background-color: rgba(0, 0, 255, .1);
}

.tips-frame {
	border-radius: 5px;
	background-color: var(--lightGray);
	border: 2px solid var(--lightGrayText);
	padding: 10px 15px;
	margin-bottom: 1em;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.tips-close-btn {
	height: fit-content;
	width: fit-content;
	padding: 10px 20px;
	margin-top: auto;
	margin-left: auto;
}

.small-box {
	font-weight: normal;
	font-size: 15px;
}

.infos-text {
	padding: 20px;
	color: #979797;
}

.ml-15 {
	margin-left: 15px;
}

.ml-30 {
	margin-left: 30px;
}

.ml-45 {
	margin-left: 45px;
}

.mr-15 {
	margin-right: 15px;
}

.mr-30 {
	margin-right: 30px;
}

.mr-45 {
	margin-right: 45px;
}

.mt-5 {
	margin-top: 5px;
}

.mt-15 {
	margin-top: 15px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-30 {
	margin-top: 30px;
}

.mb-0 {
	margin-bottom: 0px !important;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-30 {
	margin-bottom: 30px;
}

.wth-90 {
	width: 90%;
}

.wth-80 {
	width: 80%;
}

.wth-60 {
	width: 60%;
}

.wth-50 {
	width: 50%;
}

.wth-45 {
	width: 45%;
}

.wth-30 {
	width: 30%;
}

.wth-20 {
	width: 20%;
}

.mx-wth-500 {
	max-width: 500px;
}

.hidden {
	display: none !important;
}

.justify-text {
	text-align: justify;
}

.light-border {
	border: 1px solid #2d2d2d !important;
	border-radius: 5px;
}

@media screen and (max-width: 700px) {
	.page-form {
		line-height: 30px;
	}
}

.page-form {
	padding-bottom: 40px;
}

.page-form > form > div {
	margin-bottom: 30px;
}

.page-form .prev-next-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 50px;
}

.form-title {
	font-weight: 600;
	font-size: 18px;
}

.big-title {
	font-size: 30px;
}

.icon.orange {
	color: #FBA433;
}

.icon.purple {
	color: #744CAF;
}

.icon.red {
	color: #FB2B2B;
}

.icon.green {
	color: #4CAF50;
}

.icon.blue {
	color: var(--colTheme);
}

.flex {
	display: flex;
}

.flex-horizontal {
	flex-direction: row;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-left {
	justify-content: flex-start;
}

.flex-right {
	justify-content: flex-end;
}

.gap-20 {
	gap: 20px;
}

.flex-vertical-center {
	align-items: center;
}

.flex-space-between {
	justify-content: space-between;
}

.flex-space-around {
	justify-content: space-around;
}

.flex-space-evenly {
	justify-content: space-evenly;
}

.flex-center {
	justify-content: center;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-5 {
	flex: 4;
}

.flex-5 {
	flex: 5;
}

.flex-baseline {
	align-items: baseline;
}

.icon-list .list-item {
	display: flex;
	gap: 10px;
	margin: 15px 15px;
	align-items: center;
}

.icon-list .icon {
	display: flex;
	align-items: center;
}

.table {
	border-collapse: separate;
	width: 100%;
	border-spacing: 0 5px;
}


.table-header-item {
	cursor: pointer;
	background-color: var(--colLight);
	color: var(--colTheme);
	text-align: left;
}

.table-header .table-header-item:first-child {
	border-radius: 5px 0 0 5px;
}

.table-header .table-header-item:last-child {
	border-radius: 0 5px 5px 0;
}

.table-header-item {
	padding: 15px 20px;
}

.table tbody td {
	padding: 10px 20px;
}

.table-row > td {
	transition: .2s;
	margin: 20px 0;
}

.table-row > td:first-child {
	border-radius: 5px 0 0 5px;
}

.table-row > td:last-child {
	border-radius: 0 5px 5px 0;
}

.table-row:hover > td {
	background-color: var(--lightGray);
}

.table-header-item > span {
	position: relative;
}

.table-header-item .arrow-up::after,
.table-header-item .arrow-down::after {
	transition: .2s;
	content: "▲";
	transform: rotate(0deg);
	position: absolute;
	margin-left: 5px;
	left: 100%;
	width: 20px;
}

.table-header-item .arrow-down::after {
	transform: rotate(180deg);
}

.account-status {
	display: flex;
	align-items: center;
	gap: 10px;
	font-weight: 600;
	font-size: 15px;
}

.account-status .icon {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-count {
	display: flex;
	margin-top: 20px;
	margin-bottom: -20px;
	justify-content: space-around;
	border: 1px solid var(--lightGray);
	border-radius: 5px;
	padding: 10px 0;
}

.user-count .account-status {
	border: 1px solid #000;
	border-radius: 15px;
	padding: 5px;
}

.user-count .account-status .icon {
	width: 20px;
	height: 20px;
}

.user-count .account-status .icon > * {
	width: 10px;
	height: 10px;
}

.account-status.status-0 {
	color: #FBA433 !important;
	border-color: #FBA433;
}

.account-status.status-0 .icon {
	background-color: rgba(251, 164, 51, .24);
}

.account-status.status-1 {
	color: #4CAF50 !important;
	border-color: #4CAF50;
}

.account-status.status-1 .icon {
	background-color: rgba(76, 175, 80, .24);
}

.account-status.status-2 {
	color: #744CAF !important;
	border-color: #744CAF;
}

.account-status.status-2 .icon {
	background-color: rgba(116, 76, 175, .24);
}

.account-status.status-3 {
	color: #fb7e2b !important;
	border-color: #fb7e2b;
}

.account-status.status-3 .icon {
	background-color: rgba(251, 43, 43, .24);
}

.account-status.status-4 {
	color: #04B7D3 !important;
	border-color: #04B7D3;
}

.account-status.status-4 .icon {
	background-color: rgba(4, 183, 211, .24);
}

.account-status.status-5 {
	color: #FB2B2B !important;
	border-color: #FB2B2B;
}

.account-status.status-5 .icon {
	background-color: rgba(251, 43, 43, .24);
}

.account-status.status-6 {
	color: #6b1818 !important;
	border-color: #6b1818;
}

.account-status.status-6 .icon {
	background-color: rgba(107, 24, 24, .24);
}

.pointer {
	cursor: pointer;
}

.icon-btn {
	text-decoration: none;
	color: inherit;
	display: flex;
	align-items: center;
	font-weight: 600;
	gap: 10px;
	cursor: pointer;
	background-color: #ffffff;
	transition: .2s;
	padding: 10px 15px;
	border-radius: 5px;
}

.icon-btn.outline-btn {
	border: 1px solid #000;
}

.icon-btn:hover {
	background-color: #F5F5F5;
}

.icon-btn .icon {
	display: flex;
	align-items: center;
}

.icon-btn.disabled {
	pointer-events: none;
	opacity: .5;
}

.error {
	color: #FB2B2B;
	font-size: 15px;
}

.bold {
    font-weight: bold;
}

.dropdown-menu {
	position: relative;
}

.dropdown-menu .dropdown-content {
	box-sizing: content-box;
	position: absolute;
	top: 100%;
	right: 0;
	min-width: 100%;
	overflow: hidden;
	transition: .2s;
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0 1px 30px rgba(0, 0, 0, 0.16);
	z-index: 1;
}

.dropdown-menu .dropdown-content > div {
	padding: 5px;
}

.dropdown-menu .dropdown-content > div > div {
	padding: 5px 10px;
	cursor: pointer;
	white-space: nowrap;
}

.dropdown-menu .dropdown-content > div > div:hover {
	background-color: rgba(0, 0, 0, .1);
	border-radius: 5px;
}

.admin-home-table {
	display: grid;
	grid-template-columns: auto auto auto;
	text-align: center;
}

.admin-home-table .table-title {
	font-size: 20px;
	font-weight: 600;
}

.admin-home-table > div:nth-child(3n-2),
.admin-home-table > div:nth-child(2) {
	border-right: 2px solid var(--lightGray);
}

.admin-home-table > div:nth-child(1),
.admin-home-table > div:nth-child(2),
.admin-home-table > div:nth-child(3) {
	border-bottom: 2px solid var(--lightGray);
	padding: 5px;
}

.admin-home-table .subgrid {
	padding: 5px 7px 5px 10px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.company-name, .subgrid {
	border-top: 2px solid var(--lightGray);
}

.subgrid.status {
	border-top: none;
}

.admin-home-table .subgrid-3 {
	border-right: 2px solid var(--lightGray);
}

.admin-home-table .subgrid-5 {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.return-to-top {
    transition: transform .5s;
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 5px;
    background-color: var(--colTheme);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    transform: translateX(100px);
}

.return-to-top.is-visible {
    transform: translateX(0px);
}

/* HOME */
.home-page {
	background-position: center;
	background-size: cover;
}

.home-page-option {
	font-weight: 800;
	font-size: 30px;
}

.home-page-list {
	margin: 6px 0;
}

.home-page-list ul {
	list-style-type: none;
	margin: 0 0;
	padding: 0;
}

.home-page-list ul li {
	padding-left: 10px;
	margin: 12px 0;
}

.home-page-list ul li .icon {
	display: inline-block;
	margin-right: 10px;
	height: 15px;
}

.home-page-list ul li .icon img {
	max-height: 100%;
	margin-bottom: -2px;
}

.home-btn {
	background: var(--blueCSSB);
	color: white;
	font-family: inherit;
	padding: 0.35em;
	padding-left: 1.2em;
	font-size: 20px;
	font-weight: 500;
	border-radius: 0.9em;
	border: none;
	letter-spacing: 0.05em;
	display: flex;
	align-items: center;
	box-shadow: inset 0 0 1.6em -0.6em var(--blueCSSB);
	overflow: hidden;
	position: relative;
	height: 2.8em;
	padding-right: 3.3em;
	width: 100%;
	margin: 10px 0;
}

.home-btn .icon {
	background: white;
	margin-left: 1em;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 2.2em;
	width: 2.2em;
	border-radius: 0.7em;
	box-shadow: 0.1em 0.1em 0.6em 0.2em var(--blueCSSB);
	right: 0.3em;
	transition: all 0.3s;
}

.home-btn:hover .icon {
	width: calc(100% - 0.6em);
}

.home-btn .icon svg {
	width: 1.1em;
	transition: transform 0.3s;
	color: var(--blueCSSB);
}

.home-btn:hover .icon svg {
	transform: translateX(0.1em);
}

.home-btn:active svg {
	transform: scale(0.95);
}


.home-page-box {
	min-width: 330px;
	width: 50vw;
	max-width: 500px;
}

.version-display {
	position: fixed;
	bottom: 0px;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #222222;
	border: 2px rgb(0, 0, 0) solid;
	padding: 0px 8px 0px 5px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.2);
	font-size: 16px;
}

.glass {
	background: rgba(255, 255, 255, 0.3);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(7.7px);
	-webkit-backdrop-filter: blur(7.7px);
	border: 1px solid rgba(255, 255, 255, 1);
	/* padding: 50px 20px; */
}

/* Register */
.register-choices-list {
	display: flex;
	justify-content: space-around;
	gap: 15px;
	margin-top: 40px;
	font-size: 20px;
}

.register-choices-list .register-choice-item {
	transition: .1s;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
	gap: 25px;
	flex: 1;
	padding: 30px 0;
	border-radius: 5px;
	max-width: 200px;
}

.register-choices-list .register-choice-item .choice-icon {
	transition: .2s;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	margin: auto;
	padding: 25px;
	background-color: var(--colLight);
	display: flex;
	align-items: center;
	justify-content: center;
}

.register-choices-list .register-choice-item .choice-icon img {
	max-width: 100%;
}

.register-choices-list .register-choice-item:hover {
	background-color: var(--colLight);
}

.register-choices-list .register-choice-item.is-selected {
	background-color: rgba(3, 133, 229, .2);
}

.account-types-container p {
	font-weight: lighter;
	color: var(--lightGrayText);
}

@media screen and (max-width: 600px) {
	.register-choices-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.register-choices-list .register-choice-item {
		width: 100%;
		max-width: unset;
	}
}

/* Member */
.member-basic-infos {
	border: 2px solid rgba(116, 76, 175, .1);
	padding: 20px 25px;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px
}

.member-basic-infos .form-group {
	margin-top: -30px;
}

@media screen and (max-width: 600px) {
	.member-basic-infos .icon-btn .label {
		display: none;
	}

	.member-basic-infos > .icon {
		width: 30px;
	}
}

.add-companion-btn {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 7px;
	font-weight: 600;
	cursor: pointer;
}

.registration-success-container {
	display: grid;
	grid-template-columns: 5fr 3fr;
	grid-template-rows: auto auto;
	grid-template-areas: "message message"
						 "review contact";
	gap: 35px;
	padding-top: 100px;
}

.registration-success-container .success-message {
	grid-area: message;
	background-color: var(--colLight);
	padding: 35px 50px;
}

.registration-success-container .success-message .title {
	font-size: 35px;
	font-weight: 700;
	margin-bottom: 20px;
}

.registration-success-container .registration-review {
	grid-area: review;
	border-right: 1px solid #F2F2F2;
	padding-right: 35px;
}

.registration-success-container .registration-review .group-radio {
	display: flex;
	justify-content: space-between;
}

.registration-success-container .registration-contact {
	grid-area: contact;
}

.registration-success-container .registration-contact .icon-list {
	margin-top: 20px;
	color: var(--colTheme);
	font-weight: 600;
}

.small-form {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.small-form .full-page-card {
	display: flex;
	width: 500px;
	min-width: 0;
}

.normal-form {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
}

.normal-form .full-page-card {
	display: flex;
	width: 700px;
	min-width: 0;
}

.normal-form .card-content {
	display: flex;
	flex-direction: column;
}

.small-form .card-content {
	display: flex;
	flex-direction: column;
}

.small-form .login-form {
	flex: 1;
	display: flex;
	flex-direction: column;
	width: 425px;
	margin: auto;
	text-align: left;
	gap: 30px;
}

.small-form .infos-box {
	text-align: center;
}

.small-form .controls {
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding-bottom: 20px;
	padding-top: 20px;
	border-top: 1px solid #F2F2F2;
}

.small-form .controls .infos-text {
	text-decoration: none;
	padding: 0;
}

.small-form .controls .infos-text:hover {
	text-decoration: underline;
}

.small-form .btn {
	display: inline-block;
}

/* CONTACT */
.contact-form {
	padding: 2px 40px;
	display: flex;
    flex: 1;
	flex-direction: column;
}

.contact-form .card-content {
	display: flex;
	flex-direction: column;
	gap: 16px;
}